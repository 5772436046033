<template>
  <div id="offboarding-report" v-loading="loading">
    <summary-table
      title="Offboarding Summary"
      prop="offboarding"
      :companies="companies"
    />
    <property-tables :properties="propertyData" />
  </div>
</template>

<script>
import SummaryTable from './_components/SummaryTable'
import PropertyTables from './_components/PropertyTables'

import RooofAPI from '@/services/api/rooof'

export default {
  name: 'OffboardingReport',
  components: {
    'summary-table': SummaryTable,
    'property-tables': PropertyTables
  },
  data () {
    return {
      propertyData: [],
      loading: false
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies.data.filter(company => company.offboarding > 0)
    }
  },
  created () {
    this.fetchProperties()
  },
  methods: {
    /**
     * Fetch all properties of onboarding companies from the api.
     *
     * Uses the performant property summary endpoint so it
     * should be pretty snappy. Makes a request for each
     * offboarding company.
     */
    async fetchProperties () {
      try {
        this.loading = true
        const promises = []
        for (const company of this.companies) {
          promises.push(RooofAPI.companies.propertySummary(company.id))
        }
        const results = await Promise.all(promises)
        this.propertyData = results.map((result, index) => {
          return {
            company: this.companies[index],
            properties: result.filter(prop => prop.status === 'offboarding')
          }
        })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
